import { useState, useEffect } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@pankod/refine-mui";

export default function MultiSelect({
  name,
  options,
  onChange,
  value,
  labels = [],
  variant,
  disabled,
}) {
  const [selected, setSelected] = useState(value ? value : []);

  useEffect(() => {
    //console.log(value);
    if (value && value.length > 0) {
      if (value[0].id) setSelected(value.map((o) => o.id));
      else setSelected(value);
    }
  }, [value]);

  const handleChange = (index) => {
    if (!selected?.includes(index)) {
      value = [...selected, index];
    } else {
      value = selected.filter((item) => item != index);
    }
    setSelected(value);
    //console.log(value);

    if (onChange) onChange(value);
  };

  const simpleList = (objs) => {
    return objs.map((item) => {
      return (
        <Grid item xs={6} md={4} key={item.value}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                checked={selected?.includes(item.value)}
                onChange={() => {
                  handleChange(item.value);
                }}
              />
            }
            label={item.label}
          />
        </Grid>
      );
    });
  };

  const groupedList = (objs) => {
    /*sorted not in place
    const sorted = [...objs].sort((a, b) =>
      a.type > b.type ? 1 : b.type > a.type ? -1 : 0
    );*/
    const categories = [...new Set(objs.map((item) => item.type))];
    categories.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

    return categories.map((category) => {
      //console.log(category);
      //console.log(objs.filter((item) => item.type == category));

      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="button" component="div">
              {category}
            </Typography>
          </Grid>
          <Grid container xs={12} spacing={2} sx={{ pl: 4, mb: 2 }}>
            {simpleList(objs.filter((item) => item.type == category))}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <FormGroup sx={{ flex: 1 }}>
      <Grid container spacing={2}>
        {options //if options are loaded
          ? options[0]?.type //if type attribute is specified list the options sorted in subcategories
            ? groupedList(options)
            : //else (type attribute is not specified)
              simpleList(options)
          : null}
      </Grid>
    </FormGroup>
  );
}
