import { useState } from "react";

export default function useTabs(initialValue){
    const [value, setValue] = useState(initialValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          //"aria-controls": `simple-tabpanel-${index}`,
        };
      }

    return [value, handleChange, a11yProps];
}