import { useEffect, useMemo, useState } from "react";
import {
  ShowButton,
  DeleteButton,
  Stack,
  EditButton,
  CreateButton,
  TextField,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  MenuItem,
} from "@pankod/refine-mui";

import { itIT } from "@mui/x-data-grid";
import moment from "moment";

import { accommodationEnum } from "enums/accommodation";

export default function CompList() {
  const [filterValue, setFilterValue] = useState("all");
  const [filteredRows, setFilteredRows] = useState([]);

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: ["guests", "volunteers", "staff_members"],
      },
    });

  useEffect(() => {
    if (filterValue == "all")
      setFilteredRows(dataGridProps ? dataGridProps.rows : []);
  }, [dataGridProps]);

  const columns = useMemo(
    () => [
      {
        field: "nominativo",
        headerName: "Nominativo",
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row }) => {
          return (
            row.guests[0]?.name ||
            row.volunteers[0]?.name ||
            row.staff_members[0]?.name
          );
        },
      },
      {
        field: "hotel_name",
        headerName: "Hotel",
        flex: 1,
        maxWidth: 120,
      },
      {
        field: "accommodation",
        headerName: "Tipo di camera",
        flex: 1,
        minWidth: 150,
        valueGetter: ({ row }) => {
          const accValue = row.accommodation;
          return accommodationEnum.find((item) => item.value == accValue)
            ?.label;
        },
      },
      {
        field: "checkin",
        headerName: "Check-in",
        flex: 1,
        maxWidth: 80,
        valueGetter: ({ row }) => {
          const date = moment(row.checkin);
          return date.format("DD/MM");
        },
      },
      {
        field: "checkout",
        headerName: "Check-out",
        flex: 1,
        maxWidth: 80,
        valueGetter: ({ row }) => {
          const date = moment(row.checkout);
          return date.format("DD/MM");
        },
      },
      {
        field: "n_persone",
        headerName: "Nr. persone",
        flex: 1,
        maxWidth: 80,
      },
      {
        field: "note",
        headerName: "Note",
        flex: 1,
        maxWidth: 250,
      },
      {
        headerName: "Azioni",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={params.row.id} />
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const options = [
    { value: "all", label: "Tutte" },
    { value: "Valentino", label: "Valentino" },
    { value: "Michelangelo", label: "Michelangelo" },
    { value: "De Paris", label: "De Paris" },
  ];

  const handleFilter = (e) => {
    const selected = e.target.value;
    if (selected != "all") {
      setFilteredRows(
        dataGridProps.rows.filter((item) => item.hotel_name == selected)
      );
    } else {
      setFilteredRows(dataGridProps.rows);
    }
    setFilterValue(selected);
  };

  return (
    <List
      resource="bookings"
      createButtonProps={{ size: "small" }}
      headerButtons={<CreateButton>Nuovo</CreateButton>}
      headerButtonProps={{ size: "small" }}
      title={<Typography variant="h5">Prenotazioni hotel</Typography>}
    >
      <TextField
        select
        value={filterValue}
        onChange={handleFilter} //va aggiunta una variabile di stato per cambiare value
        sx={{ width: 1 / 2, mb: 2 }}
        label={"Filtra per struttura"}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <DataGrid
        {...dataGridProps}
        rows={filteredRows}
        rowCount={filteredRows.length}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
        paginationMode="client"
      />
    </List>
  );
}
