import { useMemo } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  CreateButton,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  ShowButton,
} from "@pankod/refine-mui";
import { itIT } from "@mui/x-data-grid";
import moment from "moment";

export default function CompList() {
  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: ["location"],
      },
    });
  const columns = useMemo(
    () => [
      { field: "name", headerName: "Titolo", flex: 1, minWidth: 300 },
      {
        field: "start",
        headerName: "Date e ora inizio",
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row }) => {
          return moment(row?.start).format("DD/MM/YY - hh:mm");
        },
      },
      {
        field: "location",
        headerName: "Location",
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row }) => {
          return row?.location?.name;
        },
      },
      {
        headerName: "Azioni",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={params.row.id} />
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List
      resource="events"
      createButtonProps={{ size: "small" }}
      headerButtons={<CreateButton>Nuovo</CreateButton>}
      headerButtonProps={{ size: "small" }}
      title={<Typography variant="h5">Evento</Typography>}
    >
      <DataGrid
        {...dataGridProps}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
        paginationMode="client"
      />
    </List>
  );
}
