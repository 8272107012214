import { TOKEN_KEY, API_URL } from "./constants";

// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

// refine utils
import { Refine, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "strapiDataProvider";
import { authProvider } from "authProvider";
import { accessControlProvider } from "accessControlProvider";

// utils
import { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import CustomLayout from "components/CustomLayout";
import CustomCreateEdit from "components/CustomCreateEdit";
import LoginPage from "components/user/LoginPage";
import RegisterPage from "components/user/RegisterPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import Unauthorized from "pages/Unauthorized";
import NotFound from "pages/NotFound";
import AccountDeleted from "pages/AccountDeleted";
import Privacy from "pages/Privacy";

// resources
import VolunteerList from "pages/volunteers/List";
import VolunteerFields from "pages/volunteers/Fields";
import StaffList from "pages/staffMembers/List";
import StaffFields from "pages/staffMembers/Fields";
import GuestList from "pages/guests/List";
import GuestFields from "pages/guests/Fields";
import InstitutionList from "pages/institutions/List";
import InstitutionFields from "pages/institutions/Fields";
import EventList from "pages/events/List";
import EventFields from "pages/events/Fields";
import LocationList from "pages/locations/List";
import LocationFields from "pages/locations/Fields";
import BookingList from "pages/bookings/List";
import BookingFields from "pages/bookings/Fields";

// Icons
import PeopleIcon from "@mui/icons-material/People";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HotelIcon from "@mui/icons-material/Hotel";
import InfoIcon from "@mui/icons-material/Info";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#8d99ae",
    },
    secondary: {
      main: "#2b2d42",
    },
    info: {
      main: "#edf2f4",
    },
  },
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

/* per servire l'applicazione da un sottodominio, come può essere comodo su AWS */
//ricorda di mettere anche "homepage": "/tic_backoffice" nel package.json
const { RouterComponent } = routerProvider;
const CustomRouterComponent = () => <RouterComponent basename="" />;
/*più sotto aggiorna 
<Refine
            routerProvider={{
                ...routerProvider,
                RouterComponent: CustomRouterComponent,
            }} */

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
        <RefineSnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Refine
                routerProvider={{
                  ...routerProvider,
                  RouterComponent: CustomRouterComponent,
                  routes: [
                    {
                      element: <RegisterPage />,
                      path: "/register",
                      layout: false,
                    },
                    {
                      element: <PasswordForgot />,
                      path: "/forgot-password",
                      layout: false,
                    },
                    {
                      element: <PasswordReset />,
                      path: "/reset-password",
                      layout: false,
                    },
                    {
                      element: <NotFound />,
                      path: "/not-found",
                      layout: false,
                    },
                    {
                      element: (
                        <Authenticated>
                          <Settings />
                        </Authenticated>
                      ),
                      path: "/settings",
                      layout: true,
                    },
                    // example of authorization with permissions
                    // {
                    //   element: (
                    //     <CanAccess action="customer" fallback={<Unauthorized />}>
                    //       <ProfileView />
                    //     </CanAccess>
                    //   ),
                    //   path: "/profile",
                    //   layout: false,
                    // },
                    {
                      element: <Privacy />,
                      path: "/privacy",
                      layout: false,
                    },
                    {
                      element: <AccountDeleted />,
                      path: "/account-deleted",
                      layout: false,
                    },
                  ],
                }}
                resources={[
                  // Custom Resources ----------------------
                  // {
                  //   name: "",
                  //   icon: <Icon />,
                  //   options: { label: "Label" },
                  //   list: List,
                  //   create: Create,
                  //   edit: Edit,
                  // },
                  // Standard Resources --------------------
                  // {
                  //   name: "recource-endpoint",
                  //   options: { label: "Resource Name" },
                  //   list: ResourceList,
                  //   create: () =>
                  //     CustomCreateEdit({
                  //       isCreate: true,
                  //       Fields: ResourceFields,
                  //     }),
                  //   edit: () =>
                  //     CustomCreateEdit({
                  //       isCreate: false,
                  //       Fields: ResourceFields,
                  //     }),
                  // },
                  {
                    name: "Anagrafica",
                    options: {
                      label: "Anagrafica",
                      access: "authenticated volontario",
                    },
                    icon: <InfoIcon />,
                  },
                  {
                    parentName: "Anagrafica", //per usare il menu a cascata
                    name: "volunteers",
                    options: {
                      label: "Volontari",
                      access: "authenticated volontario",
                    },
                    icon: <PeopleIcon />,
                    list: VolunteerList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: VolunteerFields,
                        label: "volontario",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: VolunteerFields,
                        label: "volontario",
                      }),
                    show: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        isShow: true,
                        Fields: VolunteerFields,
                        label: "volontario",
                      }),
                  },
                  {
                    parentName: "Anagrafica",
                    name: "staff-members",
                    options: {
                      label: "Membri Staff",
                      access: "authenticated volontario",
                    },
                    icon: <EngineeringIcon />,
                    list: StaffList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: StaffFields,
                        label: "membro staff",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: StaffFields,
                        label: "membro staff",
                      }),
                    show: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        isShow: true,
                        Fields: StaffFields,
                        label: "membro staff",
                      }),
                  },
                  {
                    parentName: "Anagrafica",
                    name: "guests",
                    options: {
                      label: "Ospiti",
                      access: "authenticated volontario",
                    },
                    icon: <AutoAwesomeIcon />,
                    list: GuestList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: GuestFields,
                        label: "ospite",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: GuestFields,
                        label: "ospite",
                      }),
                    show: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        isShow: true,
                        Fields: GuestFields,
                        label: "ospite",
                      }),
                  },
                  {
                    parentName: "Anagrafica",
                    name: "institutions",
                    options: {
                      label: "Organizzazioni",
                      access: "authenticated",
                    },
                    icon: <AccountBalanceIcon />,
                    list: InstitutionList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: InstitutionFields,
                        label: "organizzazione",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: InstitutionFields,
                        label: "organizzazione",
                      }),
                  },
                  {
                    name: "Eventi",
                    options: {
                      label: "Eventi",
                      access: "authenticated volontario",
                    },
                    icon: <LocalActivityIcon />,
                  },
                  {
                    parentName: "Eventi",
                    name: "events",
                    options: {
                      label: "Eventi",
                      access: "authenticated volontario",
                    },
                    icon: <LocalActivityIcon />,
                    list: EventList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: EventFields,
                        label: "evento",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: EventFields,
                        label: "evento",
                      }),
                    show: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        isShow: true,
                        Fields: EventFields,
                        label: "evento",
                      }),
                  },
                  {
                    parentName: "Eventi",
                    name: "locations",
                    options: {
                      label: "Locations",
                      access: "authenticated volontario",
                    },
                    icon: <ApartmentIcon />,
                    list: LocationList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: LocationFields,
                        label: "location",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: LocationFields,
                        label: "location",
                      }),
                    show: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        isShow: true,
                        Fields: LocationFields,
                        label: "location",
                      }),
                  },
                  {
                    name: "bookings",
                    options: {
                      label: "Prenotazioni Hotel",
                      access: "authenticated volontario",
                    },
                    icon: <HotelIcon />,
                    list: BookingList,
                    create: () =>
                      CustomCreateEdit({
                        isCreate: true,
                        Fields: BookingFields,
                        label: "prenotazione",
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        Fields: BookingFields,
                        label: "prenotazione",
                      }),
                    show: () =>
                      CustomCreateEdit({
                        isCreate: false,
                        isShow: true,
                        Fields: BookingFields,
                        label: "prenotazione",
                      }),
                  },
                ]}
                authProvider={authProvider}
                accessControlProvider={accessControlProvider}
                dataProvider={DataProvider(API_URL + "/api", axios)}
                notificationProvider={notificationProvider}
                Title={({ collapsed }) => (
                  <div
                    style={{
                      height: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {collapsed && (
                      <img
                        src="/logo.png"
                        alt="Logo"
                        style={{ width: "50px" }}
                      />
                    )}
                    {!collapsed && (
                      <img
                        src="/logo.png"
                        alt="Logo"
                        style={{ width: "100px" }}
                      />
                    )}
                  </div>
                )}
                Layout={CustomLayout}
                DashboardPage={Dashboard}
                ReadyPage={ReadyPage}
                LoginPage={LoginPage}
                catchAll={<ErrorComponent />}
              />
            </AuthProvider>
          </QueryClientProvider>
        </RefineSnackbarProvider>
      </LocalizationProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
