export const accessControlProvider = {
  can: async ({ resource, action, params }) => {
    let user = localStorage.getItem("user");
    if (user) user = JSON.parse(user);
    else
      return Promise.resolve({
        can: false,
        reason: "Unauthorized",
      });
    let accessArray = params?.resource?.options?.access?.split(" ") || [];
    
    //admin see everything
    if (user.role.type == "authenticated")
      return Promise.resolve({ can: true });

    // // all access pages
    if (resource == "dashboard")
      return Promise.resolve({ can: true });
    
    // default admin privileges
    //if (user.role.type == "volontario") return { can: true };
    
    // check if user with specific role has access to the page
    if (accessArray.includes(user.role.type))
      return Promise.resolve({ can: true });
    
    //default behaviour: deny access
    return Promise.resolve({
      can: false,
      reason: "Unauthorized",
    });
  },
};
