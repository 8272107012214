import { useMemo } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  CreateButton,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  ShowButton,
} from "@pankod/refine-mui";
import { itIT } from "@mui/x-data-grid";

export default function CompList() {
  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();
  const columns = useMemo(
    () => [
      { field: "name", headerName: "Nome", flex: 1, minWidth: 300 },
      { field: "email", headerName: "Email", flex: 1, minWidth: 300 },
      { field: "phone", headerName: "Telefono", flex: 1, minWidth: 200 },
      {
        field: "active",
        headerName: "Firmato",
        flex: 1,
        minWidth: 100,
        valueGetter: ({ row }) => {
          const value = row.signed;
          return value ? "Si" : "No";
        },
      },
      {
        headerName: "Azioni",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={params.row.id} />
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List
      resource="staff-members"
      createButtonProps={{ size: "small" }}
      headerButtons={<CreateButton>Nuovo</CreateButton>}
      headerButtonProps={{ size: "small" }}
      title={<Typography variant="h5">Membro staff</Typography>}
    >
      <DataGrid
        {...dataGridProps}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
        paginationMode="client"
      />
    </List>
  );
}
