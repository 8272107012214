import { useState, useEffect } from "react";

import {
  TextField,
  MenuItem,
  Grid,
  Stack,
  Switch,
  Typography,
  Autocomplete,
  Divider,
} from "@pankod/refine-mui";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

import { Controller } from "@pankod/refine-react-hook-form";
import MultiSelect from "components/MultiSelect";
import MultiSelectCheckbox from "components/MultiSelect-Checkbox";
import UploadZone from "components/UploadZone";
import GalleryManager from "components/GalleryManager";

export default function CompFields({
  fieldVariants,
  label,
  name,
  variant,
  required,
  defaultValue,
  type,
  inputProps = {},
  options,
  accessor,
  lines,
  xs,
  md,
  height,
  disabled,
  register,
  control,
  errors,
  setError,
  getValues,
  setValue,
  watch,
}) {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [dateValue, setDateValue] = useState();

  useEffect(() => {
    if (
      (type == "autocomplete" ||
        type == "multi-select" ||
        type == "multi-select-checkbox") &&
      defaultValue &&
      autocompleteValue == null
    ) {
      setValue(name, defaultValue, { shouldValidate: true });
      setAutocompleteValue(defaultValue);
    }
    if (type == "date" || type == "datetime") {
      if (defaultValue) {
        setDateValue(moment(defaultValue));
      } else {
        setValue(name, null);
      }
    }
  }, [defaultValue]);

  const defaultValueData = watch(name);

  if (type == "title")
    return (
      <Grid item xs={12} sx={{ height: height || "100%" }}>
        <Typography variant={variant} gutterBottom component="div">
          {label}
        </Typography>
      </Grid>
    );
  if (type == "space")
    return <Grid item xs={12} sx={{ height: height || "100%" }}></Grid>;
  if (type == "divider")
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        sx={{ height: height || "100%" }}
      >
        <Grid item xs={xs || 12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    );

  if (type == "date")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <DatePicker
          {...register(name, {
            required: required ? label + " Obbligatorio" : false,
          })}
          //error={!!errors[name]}
          slotProps={{ textField: { helperText: errors[name]?.message } }}
          //required={required}
          variant={fieldVariants || "standard"}
          value={dateValue}
          sx={{ width: "100%" }}
          label={label}
          type={type}
          disabled={disabled}
          onChange={(e) => {
            //console.log(e.format("YYYY-MM-DD"));
            setValue(name, e.format("YYYY-MM-DD"));
          }}
        />
      </Grid>
    );

  if (type == "datetime")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <DateTimePicker
          {...register(name, {
            required: required ? label + " Obbligatorio" : false,
          })}
          //error={!!errors[name]}
          slotProps={{ textField: { helperText: errors[name]?.message } }}
          //required={required}
          variant={fieldVariants || "standard"}
          value={dateValue}
          sx={{ width: "100%" }}
          label={label}
          type={type}
          disabled={disabled}
          onChange={(e) => {
            //console.log(e.format("YYYY-MM-DD"));
            setValue(name, e);
          }}
        />
      </Grid>
    );

  if (
    type == "text" ||
    type == "number" ||
    //type == "date" ||
    type == "time" ||
    type == "datetime"
  )
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <TextField
          {...register(name, {
            required: required ? label + " Obbligatorio" : false,
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "standard"}
          defaultValue={
            defaultValue != undefined ? defaultValue : defaultValueData
          }
          fullWidth
          label={label}
          type={type == "datetime" ? "datetime-local" : type}
          inputProps={inputProps}
          onChange={
            type == "time"
              ? (e) => {
                  //console.log(`${e.target.value}:00.000`);
                  setValue(name, `${e.target.value}:00.000`);
                }
              : () => {}
          }
          multiline={lines && lines > 1}
          rows={lines}
          disabled={disabled}
        />
      </Grid>
    );
  if (type == "switch")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle1" color="text.secondary">
            {label}
          </Typography>
          <Switch
            {...register(name, {})}
            control={control}
            checked={defaultValue || defaultValueData}
            disabled={disabled}
          />
        </Stack>
      </Grid>
    );
  if (type == "select")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <TextField
          {...register(name, {
            required: required ? label + " Obbligatorio" : false,
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "standard"}
          defaultValue={defaultValue}
          fullWidth
          label={label}
          select
          /*value={getValues(name) || defaultValue}
          onChange={(e) => {
            console.log(e.target.value);
            setValue(name, e.target.value, { shouldValidate: true });
          }}*/
          value={
            getValues(name) != null
              ? getValues(name)
              : defaultValue != null
              ? defaultValue
              : ""
          }
          onChange={(e) => {
            //console.log(e.target.value);
            setValue(name, e.target.value, { shouldValidate: true });
          }}
          disabled={disabled}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  if (type == "autocomplete")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          disabled={disabled}
          control={control}
          name={name}
          rules={{ required: required ? label + " Obbligatorio" : false }}
          render={({ field }) => (
            <Autocomplete
              {...options}
              {...field}
              value={field.value || autocompleteValue}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item[accessor] ? item[accessor] : "";
              }}
              isOptionEqualToValue={(option, value) => {
                return value === undefined || option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant={fieldVariants || "outlined"}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                  required
                />
              )}
            />
          )}
        />
      </Grid>
    );
  if (type == "multi-select")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          disabled={disabled}
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <MultiSelect
                disabled={disabled}
                name={label}
                variant={fieldVariants || "standard"}
                options={options}
                value={field.value || autocompleteValue}
                defaultValue={defaultValue}
                onChange={(val) => {
                  field.onChange(val);
                  setValue(name, val, { shouldValidate: true });
                }}
              />
            );
          }}
        />
      </Grid>
    );
  if (type == "multi-select-checkbox")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <MultiSelectCheckbox
                disabled={disabled}
                name={label}
                variant={fieldVariants || "standard"}
                options={options}
                value={field.value || autocompleteValue}
                defaultValue={defaultValue}
                onChange={(val) => {
                  field.onChange(val);
                  setValue(name, val, { shouldValidate: true });
                }}
              />
            );
          }}
        />
      </Grid>
    );
  if (type == "image")
    return (
      <Grid
        item
        xs={xs || 12}
        md={md || 12}
        sx={{
          height: height || "100%",
        }}
      >
        <UploadZone
          disabled={disabled}
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          defaultValue={defaultValue || defaultValueData}
          fieldName={name}
          required={required}
        />
      </Grid>
    );
  if (type == "gallery")
    return (
      <Grid
        item
        xs={xs || 12}
        md={md || 12}
        sx={{ height: height || "100%", mt: 2 }}
      >
        <Controller
          control={control}
          name={name}
          rules={{ required: required ? label + " Obbligatorio" : false }}
          render={({ field }) => (
            <GalleryManager
              fieldName={field.name}
              control={control}
              errors={errors}
              setError={setError}
              defaultValue={watch(field.name)}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
            />
          )}
        />
      </Grid>
    );
}
