import { useShow } from "@pankod/refine-core";
import { Create, Edit, Show, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

export default function Comp({ isCreate, isShow = false, Fields, label }) {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setError,
    setValue,
    watch,
  } = useForm();
  //console.log(saveButtonProps);
  const { data, isLoading } = useShow(); //serve per costringere Show a fare il fetch dei dati

  if (isCreate)
    return (
      <Create
        isLoading={formLoading}
        saveButtonProps={saveButtonProps}
        title={<Typography variant="h5">Crea {label}</Typography>}
      >
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
          disabled={false}
        />
      </Create>
    );

  if (isShow)
    return (
      <Show
        isLoading={isLoading}
        title={<Typography variant="h5">Visualizza {label}</Typography>}
        headerButtons={() => {}}
      >
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
          disabled={true}
        />
      </Show>
    );

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Typography variant="h5">Modifica {label}</Typography>}
      headerButtons={() => {}}
    >
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setError={setError}
        setValue={setValue}
        watch={watch}
        disabled={false}
      />
    </Edit>
  );
}
