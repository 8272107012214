import { useMemo } from "react";
import {
  EditButton,
  DeleteButton,
  ShowButton,
  Stack,
  CreateButton,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  TextField,
  MenuItem,
} from "@pankod/refine-mui";
import { useSelect } from "@pankod/refine-core";
import { itIT } from "@mui/x-data-grid";

export default function CompList() {
  const {
    dataGridProps,
    sortingMode,
    sortModel,
    onSortModelChange,
    setFilters,
  } =
    useDataGrid(/*{
    metaData: {
      populate: ["accommodation_type", "facility"],
    },
    permanentFilter: [
      {
        field: "facility.owner.id",
        operator: "eq",
        value: owner,
      },
    ],
  }*/);

  console.log(dataGridProps);
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Nome",
        flex: 1,
        minWidth: 300,
        valueGetter: ({ row }) => {
          var nominativo = `${row.name} `;
          nominativo += row.nickname != "" ? `(${row.nickname})` : "";
          return nominativo;
        },
      },
      { field: "role", headerName: "Ruolo", flex: 1, minWidth: 200 },
      {
        field: "iban",
        headerName: "IBAN",
        flex: 1,
        minWidth: 300,
      },
      /*{
        field: "accommodation_type",
        headerName: "Tipologia",
        valueGetter: ({ row }) => {
          const value = row.accommodation_type.name;
          return value;
        },
        flex: 1,
        minWidth: 150,
      },
      {
        field: "facility",
        headerName: "Struttura",
        valueGetter: ({ row }) => {
          const value = row.facility.businessName;
          return value;
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "maxGuests",
        headerName: "N max persone",
        flex: 1,
        minWidth: 50,
      },
      {
        field: "maxChildren",
        headerName: "N max bambini",
        flex: 1,
        minWidth: 50,
      },
      { field: "quantity", headerName: "Quantità", flex: 1, minWidth: 100 },
      */
      {
        headerName: "Azioni",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={params.row.id} />
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );
  /*
  const { options: facilityAC } = useSelect({
    resource: "strutture",
    optionLabel: "businessName",
    optionValue: "id",
    filters: [
      {
        field: "owner.id",
        operator: "eq",
        value: owner,
      },
      { field: "gestore", operator: "eq", value: "false" },
    ],
  });
  const options = [{ value: -1, label: "Tutte" }, ...facilityAC];

  const handleFilter = (e) => {
    const selected = e.target.value;
    console.log(e.target.value);
    //const match = options.map((item) => item.value).indexOf(e.target.value);
    if (selected >= 0)
      setFilters([
        {
          field: "facility.id",
          operator: "eq",
          value: selected,
        },
      ]);
    else {
      setFilters([]);
    }
  };
*/
  return (
    <List
      resource="guests"
      headerButtons={<CreateButton>Nuovo</CreateButton>}
      headerButtonProps={{ size: "small" }}
      title={<Typography variant="h5">Ospite</Typography>}
    >
      {/*<TextField
        select
        onChange={handleFilter}
        sx={{ width: 1 / 2, mb: 2 }}
        label={"Filtra per struttura"}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        </TextField>*/}
      <DataGrid
        {...dataGridProps}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
        paginationMode="client"
      />
    </List>
  );
}
